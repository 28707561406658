<template>
  <div>
    <empty-list v-if="!hasSnapshot && !loading" text="Ainda não há informações de documentos sincronizados.">
      <b-button @click="handleSyncronize()" v-if="is_provider && enabled">
        <b-icon icon="arrow-down-up" class="mr-2" />
        <span>Sincronizar agora</span>
      </b-button>
    </empty-list>

    <div v-else>
      <div class="d-flex align-items-center justify-content-start mb-3">
        <small class="mr-2">
          Ultima atualização {{ certificate.updated_at | dateFull }}</small>
        <unicon v-if="is_provider && enabled" name="redo" @click="handleSyncronize()" class="pointer small"
          style="font-size: 12px" height="16" width="16" />
      </div>
      <span v-if="is_provider" class="d-block mb-4">
        Caso o sistema não encontre automáticamente seus documentos, atualize-as
        no ambiente de
        <router-link class="custom-link" target="_blank" to="/my-enterprise/compliance/certificates">
          Regularidade
        </router-link>
      </span>
      <b-badge variant="light" class="d-table mt-2 mb-4">
        <div class="d-flex align-items-center">
          <span class="text-danger mr-2 h5 mb-0">*</span>
          <span>Documentos obrigatórios</span>
        </div>
      </b-badge>
      <b-card v-for="(cert, index) in certifications" :key="index"
        body-class="py-2 d-flex align-items-center justify-content-between" class="mb-3">
        <div class="d-flex">
          <span>{{ cert.name }}</span>
          <span v-if="cert.required" class="text-danger ml-2">*</span>
        </div>
        <div>
          <div>
            <b-badge :variant="variantStatus(cert.status).variant">
              {{ variantStatus(cert.status).label }}
            </b-badge>
          </div>
          <div>
            <strong :title="cert.certificate.name" v-if="cert.certificate" class="pointer d-inline" @click="openFile">{{
              truncateName(cert.certificate.name) }} <b-icon icon="paperclip" /></strong>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { url } from "@/services/api";

export default {
  data() {
    return {
      loading: false,
      enabled: false,
      service_id: "",
      provider_id: "",
      hasSnapshot: false,
      certificate: {},
      certifications: [],
    };
  },

  computed: {
    ...mapGetters(["is_preview", "user", "preview_personify", "is_provider"]),
  },
  methods: {
    ...mapActions(["get_snapshot", "set_snapshot", "show_loading"]),
    getSnapshot() {
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "certifications",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then((response) => {
        this.enabled = response.enabled;
        this.certifications = response?.data?.certifications;
        if (!response?.updated_at) {
          this.hasSnapshot = false;
        } else {
          this.hasSnapshot = true;
          this.certificate = response;
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },
    variantStatus(status) {
      let icon;
      let variant;
      let label;
      switch (status) {
        case 1:
          icon = "clock-fill";
          variant = "warning";
          label = "Expirado";
          break;
        case 2:
          icon = "x-circle-fill";
          variant = "danger";
          label = "Ausente";
          break;
        case 3:
          icon = "check-circle-fill";
          variant = "success";
          label = "Válido";
          break;
        case 4:
          icon = "check-circle-fill";
          variant = "primary";
          label = "Validação Manual";
          break;
      }
      return {
        icon,
        variant,
        label,
      };
    },
    handleSyncronize() {
      this.$bvModal.show("modal-generating-snapshot");
      this.set_snapshot({
        type: "certifications",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
    truncateName(name, maxLength = 20) {
      if (name.length > maxLength) {
        return name.slice(0, maxLength) + '...';
      }
      return name;
    },
    openFile(file) {
      const urlApi = url.replace('api', '');
      const fileUrl = `${urlApi}${file.file}`;

      window.open(fileUrl, '_blank');
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id || this.$route.query.provider_id;
    this.getSnapshot();
  },
};
</script>
<style lang="css" scoped>
/* Add link styles to make it more apparent */
.custom-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.custom-link:hover {
  color: #0056b3;
  text-decoration: none;
}
</style>
